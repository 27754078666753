import { Image } from "../atoms/Image"
import { Text } from "../atoms/Text"
import { ServiceTexts } from "../particles/DataLists"
import { Card } from "../molecules/Card"
import { useCallback } from "react"
import { Fade } from "react-awesome-reveal"
import { Button, Grid } from "@mui/material"
import { useRouter } from "next/router"


const Services = () => {
  const router = useRouter();

  const renderServiceIcon = useCallback((element: number) => {
    if (element >= 0 && element <= 3) {
      return `/images/landing-page/icon${element + 1}.svg`
    }

    return "";
  }, []);

  return (
    <section className="w-full h-auto flex flex-col items-center justify-center relative lg:px-24 md:px-20 px-6">
      <Image image={"/images/landing-page/plusGroup.png"} alt="Vector" className="absolute top-0 right-4 lg:h-36 h-24" />
      <main className="w-full pt-32 flex flex-col gap-3 items-center justify-center">
        <Fade>
          <Text as="p" className="font-light text-base text-color3/80 tracking-widest">
            {ServiceTexts.firstText}
          </Text>
        </Fade>
        <Fade>
          <Text as="h2" className="md:text-4xl text-2xl font-medium capitalize text-color3">
            {ServiceTexts.secondText}
          </Text>
        </Fade>

        <div className="w-full h-auto grid lg:grid-cols-4 md:grid-cols-2 lg:gap-7 md:gap-10 gap-7 my-12 z-20 px-8 md:px-0">
          {
            ServiceTexts.cards.map((card, index) => (

              <Card onClick={()=> router.push(card.href)} cardClass="w-full bg-white flex flex-col items-center justify-center py-6 cursor-pointer transition duration-300 hover:shadow-xl px-5 rounded-xl cardPseudo after:bg-color1" imageWrapperClass="w-28 h-28 relative z-10 before:content-[''] before:absolute before:top-3 before:right-3 before:w-16 before:h-16 before:bg-color2/30 before:-z-10 before:rounded-tl-3xl before:rounded-br-3xl" cover="object-cover" imageAlt={card.firstText} imageSrc={renderServiceIcon(index)} textWrapperClass="w-full flex flex-col items-center gap-2" key={index} >
                <Text as="h4" className="text-base rounded font-medium text-color3">
                  {card.firstText}
                </Text>
                <Text as="p" className="text-sm  font-light text-center text-color3">
                  {card.secondText}
                </Text>
              </Card>
            ))
          }
        </div>
        <Grid>
          <Grid xs={12} item>
            <Button variant='contained' onClick={()=>router.push('/book/discover/best/1/')}>Start Reading</Button>
          </Grid>
        </Grid>
      </main>

    </section>
  )
}

export default Services
