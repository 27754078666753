export type NavItem = {
  name: string,
  url: string
}
export const NavLinks: NavItem[] = [
];

export const NavButtons = [
  {
    name: "Login",
    url: "/login/"
  },
  {
    name: "Signup",
    url: "/register/"
  }

];


export const HeroTexts = {
  firstText: "EXPLORING LITERARY WORLDS: A HUB FOR BOOK LOVERS AND WORD WEAVERS",
  secondText: "Read, immerse, embrace stories",
  thirdText: "Dive into crafted prose, where every page unveils a world of stories waiting to be explored. Discover the art of words on our literary haven.",
  firstButton: "Start Reading",
  secondButton: 'Write Your Book'
}


export const ServiceTexts = {
  firstText: "CATEGORY",
  secondText: "We Offer Best Books",
  cards: [
    {
      firstText: "Fanfiction",
      secondText: "Breathes life into beloved universes with imaginative storytelling twists.",
      href: "/book/discover/fanfiction/1/"
    },
    {
      firstText: "Romance",
      secondText: "Captivating love stories evoke emotions in enchanting romance book journeys.",
      href: "/book/discover/romance/1/"
    },
    {
      firstText: "Science Fiction",
      secondText: "Futuristic worlds unfold in mind-bending tales of science fiction books.",
      href: "/book/discover/sciencefiction/1/"
    },
    {
      firstText: "Adventure",
      secondText: "Thrilling adventures beckon, unveiling daring escapades in captivating adventure books.",
      href: "/book/discover/adventure/1/"
    }
  ]
}

export const TopDestinationTexts = {
  firstText: "Recommendations",
  secondText: "Top Books",
  cards: [
    {
      bookName: "The Cellar",
      views: "29M",
      category: "adventure"
    },
    {
      bookName: "London, UK",
      views: "4.2k",
      category: "12 days trip"
    },
    {
      bookName: "Paris, France",
      views: "15k",
      category: "26 days trip"
    },
    {
      bookName: "New York, USA",
      views: "4.5k",
      category: "8 days trip"
    },
    {
      bookName: "Warsaw, Poland",
      views: "3.9k",
      category: "14 days trip"
    },
    {
      bookName: "Dubai, UAE",
      views: "9.2k",
      category: "17 days trip"
    },
  ]
}

export const BookingStepsTexts = {
  firstText: "Easy and Fast",
  secondText: "Book your next trip in 3 easy steps",
  listOfSteps: [
    {
      text: "Choose Destination lorem ipsum dolor sit amet, consectetur adipiscing elit.Urna, tortor tempus."
    },
    {
      text: "Make Payment lorem ipsum dolor sit amet, consectetur adipiscing elit.Uma, totor tempus."
    },
    {
      text: "Reach Airport on Selected Date lorem ipsum dolor sit amet.consectetur adipiscing elit.Uma totor tempus."
    }
  ],
  cardOne:
  {
    name: "Trip To Greece",
    date: "14-29 June  |  by Robbin Jobs",
    people: "24 people going"
  },
  cardTwo: {
    status: "Ongoing",
    destination: "Trip to Rome",
    completion: "40% Completed"
  }

}

export const TestimonialTexts = {
  firstText: "TESTIMONIALS",
  secondText: "What people say about Us.",
  feedBacks: [
    {
      text: "Discovering this online book haven has transformed my reading experience completely! The vast library, seamless navigation, and the convenience of reading from anywhere have made my literary journey truly remarkable. Kudos to the platform for enhancing my love for books!",
      person: "Mike Taylor",
      location: "New York, United States"
    },
    {
      text: "Online reading made easy! Diverse books, seamless experience—thank you, satisfied reader!",
      person: "Gina Howards",
      location: "Toronto, Canada"
    },
    {
      text: "Discovering this fanfiction haven enhanced my reading journey—diverse, convenient, and thrilling!",
      person: "Regina Santos",
      location: "São Paulo, Brazil"
    },
    {
      text: "I am delighted by the captivating stories on this platform. What a marvel! The convenience and immersive experience make it my go-to literary haven. Thank you for bringing such joy to my reading journey!",
      person: "Adèle Dubois",
      location: "Lille, France"
    },
  ],
}

export const NewsletterTexts = {
  firstText: "Subscribe to get information, latest news and other interesting offers about Inknovl.",
  placeholderText: "Your email",
  buttonText: "Subscribe"
}

export const FooterTexts = {
  underLogoText: "Exploring literary worlds: a hub for book lovers and word weavers",
  quickLinks: {
    caption: "Book Categories",
    links: [
      {
        name: "Fanfiction",
        url: "/book/discover/fanfiction/1/"
      },
      {
        name: "Romance",
        url: "/book/discover/romance/1/"
      },
      {
        name: "Science Fiction",
        url: "/book/discover/sciencefiction/1/"
      },
      {
        name: "Adventure",
        url: "/book/discover/adventure/1/"
      },
      {
        name: "Fantasy",
        url: "/book/discover/fantasy/1/"
      },
      {
        name: "Mystery",
        url: "/book/discover/mystery/1/"
      }
    ]
  },
  contacts: {
    caption: "Quick Links",
    links: [
      {
        name: "Privacy Policy",
        url: "/privacy"
      },
      {
        name: "Terms & Conditions",
        url: "/terms"
      }
    ]
  },
  more: {
    caption: "More",
    links: [
      {
        name: "Login",
        url: "/login"
      },
      {
        name: "Signup",
        url: "/register"
      },
      {
        name: "Search",
        url: "/book/search/?query=&pageNumber=1"
      }
    ]
  }
}
