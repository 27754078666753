import { Button } from "@mui/material"
import { Image } from "../atoms/Image"
import { Text } from "../atoms/Text"
import { HeroTexts } from "../particles/DataLists"
import { Fade, Slide } from "react-awesome-reveal"
import { useRouter } from "next/router"


const HeroSection = () => {
  const router = useRouter();

  return (
    <section className="w-full h-auto relative flex justify-end overflow-visible">
      <Image className="h-[60%] w-[80%] lg:h-[90vh] md:h-[50vh] lg:w-1/2 md:w-[55%] absolute top-0 right-0" image={"/images/landing-page/HeroVector.png"} alt="Hero Background Vector" objectCover="absolute right-0" />
      <main className="w-full lg:h-full h-auto grid md:grid-cols-2 lg:px-24 md:px-8 px-5 pt-24 md:pt-32 lg:pt-0">
        <div className="flex flex-col justify-center md:gap-6 gap-3 md:order-1 order-2">
          <Fade>
            <Text as="p" className="text-color1 uppercase tracking-widest lg:text-base  text-sm font-normal">{HeroTexts.firstText}</Text>
          </Fade>
          <Fade>
            <Text as="h1" className="text-color3 lg:text-5xl md:text-3xl text-2xl font-medium">
              {HeroTexts.secondText}
            </Text>
          </Fade>
          <Fade>
            <>
              {HeroTexts.thirdText}
            </>
          </Fade>
          <div className="w-full flex md:justify-start justify-between items-center lg:gap-12 md:gap-6 gap-0">
            <Button style={{fontSize: "0.78rem"}} variant='contained' href="/book/discover/best/1/" onClick={(e) => {
              e.preventDefault();
              router.push('/book/discover/best/1/');
            }}>
              {HeroTexts.firstButton}
            </Button>
            <Button style={{fontSize: "0.78rem"}} variant="outlined" href="/login/" onClick={(e) => {
              e.preventDefault();
              router.push('/login/');
            }}>
              {HeroTexts.secondButton}
            </Button>
          </div>
        </div>
        <div className="flex flex-col items-center justify-end md:order-2 order-1 pb-5">
          <Slide direction="right">
            <Image image={"/images/landing-page/typewriter.png"} alt="Hero Image" className=" lg:h-[85%] lg:w-[90%] md:h-[100%] md:w-full w-[90%] h-[30vh]" />
          </Slide>

        </div>
      </main>
    </section>
  )
}

export default HeroSection
