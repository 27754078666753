import { Link } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Slide } from "react-awesome-reveal";
import { Button } from "../atoms/Button";
import { Image } from "../atoms/Image";
import { List } from "../atoms/List";
import { NavButtons, NavLinks } from "../particles/DataLists";



const NavBar = () => {

  const route = useRouter();

  // const [scrollY, setScrollY] = useState(0)
  const [navBarColor, setNavBarColor] = useState(false)

  const listenScrollEvent = () => {
    window.scrollY > 10 ? setNavBarColor(true) : setNavBarColor(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);


  return (
    <header className="w-full h-auto bg-transparent overflow-x-hidden fixed z-50 top-0 left-0">
      <Slide direction="down">
        <nav className={`w-full md:h-24 h-20 ${navBarColor ? "bg-white" : "bg-transparent"} lg:px-24 md:px-12 px-8 flex justify-between items-center`}>
          <Image as="a" href="/" className="md:h-10 h-8" image={"/images/landing-page/logo1.png"} alt="Logo" />
          <div className="lg:flex items-center gap-20">
            <ul className="flex items-center justify-center gap-8">
              {
                NavLinks.map((navlink, index) => (
                  <List className="w-full text-base" key={index}>
                    <Link href={navlink.url} className="relative inline-block overflow-hidden pt-2 pl-2 before:w-2 before:h-2 before:bg-color2 before:absolute before:top-2 before:-left-10 before:rounded-full before:transition-all before:duration-200 before:ease-in hover:before:left-0.5 after:w-0.5 after:h-3 after:bg-color2 after:absolute after:left-1 after:-top-10 hover:after:top-3.5 after:transition-all after:duration-200 after:ease-in">{navlink.name}</Link>
                  </List>
                ))
              }

            </ul>
            <ul className="flex items-center justify-center gap-6">
              {
                NavButtons.map((navbutton, index) => (
                  <List className="w-full" key={index}>
                    <Button onClick={() => route.push(navbutton.url)} type="button" className={`${navbutton.name === "Signup" ? "border-2 border-gray-950 before:top-0" : "before:bottom-0 border-b-2 border-transparent hover:border-gray-950"} py-2 px-8 relative z-10 before:content-[''] before:absolute before:left-0 before:w-full before:h-0 before:bg-color2 before:-z-10 hover:before:h-full before:transition-all before:duration-300 before:ease-in text-base`}>{navbutton.name}</Button>
                  </List>
                ))
              }
            </ul>
          </div>
        </nav>
      </Slide>
    </header >
  )
}

export default NavBar
