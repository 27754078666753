import { Button, Grid } from "@mui/material"
import { useRouter } from "next/router"
import { Fade } from "react-awesome-reveal"
import { Text } from "../atoms/Text"
import AutoModeIcon from '@mui/icons-material/AutoMode';


const BookRoulette = () => {
  const router = useRouter();

  return (
    <section className="w-full h-auto flex flex-col items-center justify-center relative lg:px-24 md:px-20 px-6">
      <main className="w-full pt-32 flex flex-col gap-3 items-center justify-center">
        <Fade>
          <Text as="h2" className="md:text-4xl text-2xl font-medium capitalize text-color3">
            Spin the Book Roulette
          </Text>
        </Fade>
        <Fade>
          <Text as="p" className="font-light text-base text-color3/80 tracking-widest">
            Don't know what to read?
          </Text>
        </Fade>
        <Grid pt={20}>
          <Grid xs={12} item>
            <Button startIcon={<AutoModeIcon/>} variant='contained' onClick={()=>router.push('/book/roulette/')}>Book Roulette</Button>
          </Grid>
        </Grid>
      </main>
    </section>
  )
}

export default BookRoulette
