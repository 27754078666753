import { ReactNode } from "react";
import BlankLayout from "src/@core/layouts/BlankLayout";
import Footer from "src/views/landing-page/organs/Footer";
import HeroSection from "src/views/landing-page/organs/HeroSection";
import NavBar from "src/views/landing-page/organs/NavBar";
import Services from "src/views/landing-page/organs/Services";
import Testimonials from "src/views/landing-page/organs/Testimonials";
import '../views/landing-page/landing-page.css';
import BookRoulette from "src/views/landing-page/organs/BookRoulette";
import { Grid } from "@mui/material";
import { Adsense } from "@ctrl/react-adsense";

const Index = () => {
  return (
    <div className="w-full bg-white text-gray-950 font-poppins">
      <NavBar />
      <HeroSection />
      <Grid pt={6} spacing={6} container>
        <Grid xs={12} item>
          <Adsense client={process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_ID as string} slot={process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_SLOT as string} responsive={'true'} />
        </Grid>
      </Grid>
      <BookRoulette />
      <Services />
      <Grid pt={6} spacing={6} container>
        <Grid xs={12} item>
          <Adsense client={process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_ID as string} slot={process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_SLOT as string} responsive={'true'} />
        </Grid>
      </Grid>
      <Testimonials />
      <Footer />
    </div>
  )
}

Index.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

export default Index
